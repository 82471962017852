<template>
    <div>
        <el-card style="margin-top: 8px">
            <div slot="header" class="card-header">
                <span>添加员工与绑定角色</span>
            </div>

            <!-- 添加新员工-->
            <el-card style="margin-top: 20px">
                <!--标题-->
                <el-row class="title" :gutter="100" type="flex">
                    <el-col :span="4" class="content1">
                        <el-button type="text" @click="jumpToStaff" class="font1" :disabled="!hasCreateStaffPrivilege">
                            添加新员工
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font2">
                        <el-tooltip
                            class="font2"
                            style="text-decoration: underline"
                            :content="roleNamesForCreateStaff"
                            placement="top"
                        >
                            <span>此类角色</span>
                        </el-tooltip>
                        可添加新员工
                    </el-col>
                </el-row>
                <!--步骤-->
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button type="text" class="font2" @click="jumpToStaff" :disabled="!hasCreateStaffPrivilege"
                            >①添加员工</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>
                            点击新建-添加-选择员工类型(收银员、店长、门店财务为业务型/总经理助理、连锁财务为管理型)、用户名、姓名、性别、手机号、年龄、身份证号、部门选择门店-点击保存
                        </span>
                    </el-col>
                </el-row>
            </el-card>

            <!--绑定员工角色-->
            <el-card style="margin-top: 20px">
                <!--标题-->
                <el-row class="title" :gutter="100" type="flex">
                    <el-col :span="4" class="content1">
                        <el-button
                            type="text"
                            @click="jumpToStaff"
                            class="font1"
                            :disabled="!hasQueryStaffRolePrivilege"
                            >绑定员工角色</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font2">
                        <span>
                            <el-tooltip
                                class="font2"
                                style="text-decoration: underline"
                                :content="roleNamesForQueryStaffRole"
                                placement="top"
                            >
                                <span>此类角色</span>
                            </el-tooltip>
                            <span>可为员工绑定角色</span>
                        </span>
                    </el-col>
                </el-row>
                <!--步骤-->
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToStaff"
                            :disabled="!hasQueryStaffRolePrivilege"
                            >①绑定角色</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>在需要分配角色的员工处点击分配角色-勾选角色-确定</span>
                    </el-col>
                </el-row>
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToStaff"
                            :disabled="!hasEditStaffCashPrivilege"
                        >
                            ②收银机折扣权利
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>点击收银机设置可为收银员、店长角色的人员设置折扣权利-默认10折为不打折-确定</span>
                    </el-col>
                </el-row>
            </el-card>

            <!--选择工作机构-->
            <el-card style="margin-top: 20px">
                <!--标题-->
                <el-row class="title" :gutter="100" type="flex">
                    <el-col :span="4" class="content1">
                        <el-button
                            type="text"
                            @click="jumpToDeptGroup"
                            class="font1"
                            :disabled="!hasOpenDeptGroupPrivilege"
                        >
                            选择工作机构
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font2">
                        <span>
                            <el-tooltip
                                class="font2"
                                style="text-decoration: underline"
                                :content="roleNamesForOpenDeptGroup"
                                placement="top"
                            >
                                <span>此类角色</span>
                            </el-tooltip>
                            <span>可为员工选择工作机构</span>
                        </span>
                    </el-col>
                </el-row>
                <!--步骤-->
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToDeptGroup"
                            :disabled="!hasOpenDeptGroupPrivilege"
                        >
                            ①分配员工到机构
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>
                            点击人员设置-查询条件处输入姓名或手机号-查询并添加-指定机构-勾选机构-确定完成后该员工即可在收银机登录收款
                        </span>
                    </el-col>
                </el-row>
            </el-card>
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';

const staffPrivilegeFlag = 'menu.system.staff';
const createStaffPrivilegeFlag = `${staffPrivilegeFlag}.create`;

const queryStaffRolePrivilegeFlag = 'biz.roleStaff.query';
const editStaffCashPrivilegeFlag = 'biz.system.staffCash.edit';

const deptGroupPrivilegeFlag = 'menu.system.deptGroup';
const openDeptGroupPrivilegeFlag = 'menu.system.deptGroup.open';

export default {
    name: 'DeptOperateStaffGuide',
    data() {
        return {
            rolesForCreateStaff: [],
            rolesForQueryStaffRole: [],
            rolesForOpenDeptGroup: [],
        };
    },
    created() {
        //查询能够创建员工的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(createStaffPrivilegeFlag).then((rst) => {
            this.rolesForCreateStaff = rst;
        });
        //查询"能够查询员工角色"的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(queryStaffRolePrivilegeFlag).then((rst) => {
            this.rolesForQueryStaffRole = rst;
        });
        //查询"能够查询机构组"的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(openDeptGroupPrivilegeFlag).then((rst) => {
            this.rolesForOpenDeptGroup = rst;
        });
    },
    computed: {
        roleNamesForCreateStaff() {
            return this.rolesForCreateStaff.map((e) => e.name).join('，');
        },
        roleNamesForQueryStaffRole() {
            return this.rolesForQueryStaffRole.map((e) => e.name).join('，');
        },
        roleNamesForOpenDeptGroup() {
            return this.rolesForOpenDeptGroup.map((e) => e.name).join('，');
        },
    },

    methods: {
        jumpToStaff() {
            Util.nameJump(this, staffPrivilegeFlag);
        },
        jumpToDeptGroup() {
            Util.nameJump(this, deptGroupPrivilegeFlag);
        },
        hasCreateStaffPrivilege() {
            return this.hasPrivilege(createStaffPrivilegeFlag);
        },
        hasQueryStaffRolePrivilege() {
            return this.hasPrivilege(queryStaffRolePrivilegeFlag);
        },
        hasEditStaffCashPrivilege() {
            return this.hasPrivilege(editStaffCashPrivilegeFlag);
        },
        hasOpenDeptGroupPrivilege() {
            return this.hasPrivilege(openDeptGroupPrivilegeFlag);
        },
    },
};
</script>

<style scoped>
.font1 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: bold;
    text-decoration: underline;
    color: #36bf5d;
    line-height: 28px;
}

.font2 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #606266;
    line-height: 20px;
}

.font3 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #9ca1ab;
    line-height: 20px;
}
.title {
    background: #d6ffe2;
    border-radius: 4px 4px 0 0;
}

.content1 {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content2 {
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.el-row {
    height: 70px;
}

.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
    color: #c0c4cc;
    cursor: not-allowed;
}

/deep/ .el-card__body {
    padding-top: 0;
}

.card-header {
    font-size: 20px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #212121;
    line-height: 28px;
}
</style>
